import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout"
import HeadMeta from "../components/HeadMeta"
import PageHeader from "../components/PageHeader"
import Breadcrumb from "../components/Breadcrumb"
import BrowseList from "../components/BrowseList"
import Phrase from "../components/Phrase"

export default props => {
  const { data } = props
  const fields = data.phrasesJson.fields || {}

  const pageTitle = (
    <>
      Phrase: <Phrase text={fields?.name} />
    </>
  )

  return (
    <Layout>
      <HeadMeta pageTitle={pageTitle} />
      <PageHeader>{pageTitle}</PageHeader>
      <Breadcrumb
        route={[
          { name: "Multilingual library signage", href: "/" },
          { name: "Phrases", href: "/phrase" },
          { name: <Phrase text={fields?.name} />, href: `/${fields?.slug}` },
        ]}
      />
      <h2>Translations</h2>
      <BrowseList
        items={data.allTranslationsJson.edges.map((edge) => {
          return {
            fields: {
              name: edge?.node?.fields?.languageName,
              slug: edge?.node?.fields?.slug,
            },
          }
        })}
        sortAlpha={true}
      />
    </Layout>
  )
}

export const query = graphql`
  query PhrasePageQuery($phraseID: Int) {
    phrasesJson(fields: {id: {eq: $phraseID}}) {
      fields {
        id
        name
        slug
        langRestrict
      }
    }
    allTranslationsJson(filter: {fields: {enabled: { eq: true }, phraseID: {eq: $phraseID}}}) {
      edges {
        node {
          fields {
            phraseID
            slug
            languageName
            phraseName
            id
          }
        }
      }
    }
  }
`
